import { NavbarItems } from '../../types/Config/Navbar';
import { Locale } from '../../types/Locale';

const filterNavigationForLocale = (
  navigationItems: NavbarItems,
  lang: string,
): NavbarItems => {
  const navItemsForLocale = navigationItems.filter((navItem) =>
    navItem.visibleInLocales.includes(lang as Locale),
  );

  const navItemsAndSubitemsForLocale = navItemsForLocale.map((navItem) => {
    if ('subitems' in navItem) {
      navItem.subitems = navItem.subitems.filter((subitem) => {
        if (subitem.visibleInLocales) {
          return subitem.visibleInLocales.includes(lang as Locale);
        }
      });
    }
    return navItem;
  });

  return navItemsAndSubitemsForLocale;
};

export default filterNavigationForLocale;
