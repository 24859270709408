import { Locale } from '../../Locale';

export interface LocalNavbarItem {
  id?: string;
  label: string;
  routeName: string;
  hash?: string;
  visibleInLocales: Locale[];
}

export interface ExternalNavbarItem {
  id?: string;
  label: string;
  externalUrls: { [domain: string]: string };
  openInNewTab?: boolean;
  visibleInLocales: Locale[];
}

export interface NavbarLocalSubitem {
  description: string;
  label: string;
  hash?: string;
  routeName: string;
  visibleInLocales: Locale[];
}

export interface NavbarExternalSubitem {
  description: string;
  label: string;
  externalUrls: { [domain: string]: string };
  openInNewTab?: boolean;
  visibleInLocales: Locale[];
}

export interface SidebarItem {
  label: string;
  routeName: string;
  visibleInLocales?: string[];
}

export type NavbarSubitem =
  | NavbarLocalSubitem
  | NavbarExternalSubitem
  | SidebarItem;

export interface NavbarItemGroup {
  id?: string;
  label: string;
  routeName?: string;
  subitems: NavbarSubitem[];
  visibleInLocales: Locale[];
}

export type NavbarItem = LocalNavbarItem | ExternalNavbarItem | NavbarItemGroup;
export type NavbarItems = NavbarItem[];

export const isNavbarItemGroup = (
  navItem: NavbarItem,
): navItem is NavbarItemGroup => {
  return (navItem as NavbarItemGroup).subitems !== undefined;
};

export const isLocalNavbarItem = (
  navItem: NavbarItem,
): navItem is LocalNavbarItem => {
  return (
    (navItem as LocalNavbarItem).routeName !== undefined &&
    (navItem as NavbarItemGroup).subitems === undefined
  );
};

export const isExternalNavbarItem = (
  navItem: NavbarItem,
): navItem is ExternalNavbarItem => {
  return (
    (navItem as ExternalNavbarItem).externalUrls !== undefined &&
    (navItem as NavbarItemGroup).subitems === undefined
  );
};

export interface NavbarConfig {
  navbarItems: NavbarItems;
}
