import React from 'react';

import classes from './index.module.scss';
import MainFooterContent from './MainFooterContent';

interface Props {
  simpleUI?: boolean;
}

const Footer: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  simpleUI,
}) => {
  return (
    <footer className={classes.footerContainer}>
      {simpleUI ? (
        <div className={classes.simpleFooterContent}>
          © SMSAPI by LINK Mobility
        </div>
      ) : (
        <div className={classes.mainFooterContainer}>
          <MainFooterContent />
        </div>
      )}
    </footer>
  );
};

export default Footer;
