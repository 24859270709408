import React, { useContext } from 'react';

import ConfigsContext from '../../contexts/configs';
import Badge from '../Badge';

import TuvBlueSvg from './tuv-blue.svg';

const Iso27001Badge: React.FunctionComponent = () => {
  const { externalUrls } = useContext(ConfigsContext);

  if (!externalUrls.isoBadge) return null;

  return <Badge href={externalUrls.isoBadge} logoSrc={TuvBlueSvg} />;
};

export default Iso27001Badge;
