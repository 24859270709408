import React, { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';

import ConfigsContext from '../../contexts/configs';
import Badge from '../Badge';
import { ZaufaneRating } from '../../resources/zaufane';

import ZaufaneLogo from './zaufane_pl.png';

interface Props extends ZaufaneRating {
  isDark?: boolean;
}

const ZaufaneBadge: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  rating,
  ratingsCount,
  isDark = false,
}) => {
  const { t } = useTranslation('zaufaneBadge');
  const { services } = useContext(ConfigsContext);

  return (
    <Badge
      description={t('%{rating}/5 out of %{count} reviews', {
        rating,
        count: ratingsCount,
      })}
      title={t('SATISFIED CUSTOMERS')}
      href={services.zaufane?.statsUrl}
      logoSrc={ZaufaneLogo}
      rel="noopener nofollow"
      target="_blank"
      isDark={isDark}
    />
  );
};

export default ZaufaneBadge;
