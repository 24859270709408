import React, { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';

import classes from './index.module.scss';

import { Logo } from '@/components/Navbar/components/Logo';
import { LocaleChanger } from '@/components/Footer/LocaleChanger';
import { SocialMedia } from '@/components/Footer/SocialMedia';
import ZaufaneBadge from '@/components/ZaufaneBadge';
import Iso27001Badge from '@/components/Iso27001Badge';
import { ZaufaneContext } from '@/contexts/zaufane';
import FooterNavigation from '@/components/Footer/FooterNavigation';
import ConfigsContext from '@/contexts/configs';

const MainFooterContent: React.FunctionComponent = () => {
  const { t } = useTranslation('footer');
  const zaufane = useContext(ZaufaneContext);
  const {
    externalUrls: { isoBadge },
  } = useContext(ConfigsContext);

  return (
    <div className={classes.layout}>
      <Logo />
      <h1 className={classes.textPhrase}>
        {t('Powerful and easy SMS communication')}
      </h1>
      {(isoBadge || zaufane) && (
        <div className={classes.trustIcons}>
          <Iso27001Badge />
          {zaufane && (
            <ZaufaneBadge
              rating={zaufane.rating}
              ratingsCount={zaufane.ratingsCount}
            />
          )}
        </div>
      )}
      <FooterNavigation />
      <SocialMedia />
      <LocaleChanger />
    </div>
  );
};

export default MainFooterContent;
