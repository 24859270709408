import React, { ReactNode, useContext } from 'react';

import ConfigsContext from '../../../contexts/configs';

import classes from './index.module.scss';
import {
  Android,
  Apple,
  Facebook,
  GitHub,
  Instagram,
  LinkedIn,
  Twitter,
  YouTube,
} from './icons';

const icons: Record<string, ReactNode> = {
  android: <Android />,
  apple: <Apple />,
  facebook: <Facebook />,
  github: <GitHub />,
  instagram: <Instagram />,
  linkedin: <LinkedIn />,
  twitter: <Twitter />,
  youtube: <YouTube />,
};

export const SocialMedia: React.FunctionComponent = () => {
  const configs = useContext(ConfigsContext);

  return (
    <ul className={classes.socialMediaUrls}>
      {configs.socialMedia.icons.map((socialMediaIcon) => (
        <li
          className={classes.socialMediaUrlsItem}
          key={`${socialMediaIcon.name} icon`}
        >
          <a
            className={classes.socialMediaUrlsAnchor}
            href={socialMediaIcon.url}
            target="_blank"
            rel="noopener"
            data-gtm="social-media-url"
          >
            {icons[socialMediaIcon.name]}
          </a>
        </li>
      ))}
    </ul>
  );
};
