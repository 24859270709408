import { useContext } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import useTranslation from 'next-translate/useTranslation';

import { NavbarItem } from '../../types/Config/Navbar';
import ConfigsContext from '../../contexts/configs';
import filterNavigationForLocale from '../../helpers/filterNavigationForLocale';

export const useFooterConfig = (): NavbarItem[] => {
  const { lang } = useTranslation();
  const configs = useContext(ConfigsContext);

  const clonedConfig = cloneDeep(configs.footer);

  return filterNavigationForLocale(clonedConfig.footerItems, lang);
};
