import React, { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { I18nLink } from '../../I18nLink';
import { DEFAULT_CONFIG_KEY } from '../../../constants';

import classes from './index.module.scss';

import { isNavbarItemGroup, NavbarSubitem } from '@/types/Config/Navbar';
import { useFooterConfig } from '@/hooks/useFooterConfig';
import ConfigsContext from '@/contexts/configs';

const FooterNavSubitem: React.FunctionComponent<{
  navItem: NavbarSubitem;
  locale: string;
}> = ({ navItem, locale }) => {
  const { t } = useTranslation('footer');

  const externalUrl =
    'externalUrls' in navItem
      ? navItem.externalUrls[locale] || navItem.externalUrls[DEFAULT_CONFIG_KEY]
      : undefined;
  const navItemHref =
    'routeName' in navItem ? `/${navItem.routeName}` : externalUrl;
  const navItemHash = 'hash' in navItem ? navItem.hash : undefined;

  if (!navItemHref) {
    return null;
  }

  return 'externalUrls' in navItem ? (
    <a
      className={classes.footerNavAnchor}
      href={navItemHref}
      target={navItem.openInNewTab ? '_blank' : undefined}
      rel={navItem.openInNewTab ? 'noopener' : undefined}
    >
      {t(navItem.label)}
    </a>
  ) : (
    <I18nLink href={navItemHref} hash={navItemHash}>
      <a className={classes.footerNavAnchor}>{t(navItem.label)}</a>
    </I18nLink>
  );
};

const gridConfigToCssString = (cssGridAreasLayout: string[]): string => {
  return cssGridAreasLayout.map((value) => `"${value}"`).join(' ');
};

const FooterNavigation: React.FunctionComponent = () => {
  const { t, lang } = useTranslation('footer');

  const configs = useContext(ConfigsContext);
  const footerNavItems = useFooterConfig();

  const footerItems = footerNavItems.map((footerItem) => {
    if (isNavbarItemGroup(footerItem)) {
      return (
        <div
          className={classes.footerNavGroupLayout}
          key={footerItem.label}
          style={{ gridArea: footerItem.id }}
        >
          <h2 className={classes.footerNavGroupHeader}>
            {t(footerItem.label)}
          </h2>
          <div className={classes.footerNavGroupItems}>
            {footerItem.subitems.map((subitem) => {
              return (
                <FooterNavSubitem
                  navItem={subitem}
                  locale={lang}
                  key={subitem.label}
                />
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  });

  return (
    <div
      className={classes.layout}
      style={{
        gridTemplateAreas: gridConfigToCssString(
          configs.footer.cssGridAreasLayout,
        ),
      }}
    >
      {footerItems}
    </div>
  );
};

export default FooterNavigation;
