import React, { useContext } from 'react';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { faEarthAfrica } from '@fortawesome/pro-regular-svg-icons';
import Image from 'next/image';

import { HOME_ROUTE } from '../../../constants';

import classes from './index.module.scss';

import ConfigsContext from '@/contexts/configs';
import { I18nContext } from '@/contexts/i18n';
import { useInternationalizedHref } from '@/hooks/useInternationalizedHref';
import triangleDownSvg from '@/images/common/triangle-down.svg';
import { FaIconLight } from '@/components/FaIconLight';

export interface SelectOption {
  disabled?: boolean;
  label: string;
  value: string;
}

interface Props {
  options: SelectOption[];
  selectedOption: SelectOption;
  label: (value: string) => React.ReactNode;
  onChange: (value: SelectOption) => void;
}

export const CustomLabelSelect: React.FC<Props> = ({
  options,
  selectedOption,
  label,
  onChange,
}) => {
  const { t } = useTranslation('navbar');

  return (
    <div className={classes.container}>
      <label className={classes.selectLabel}>
        <div>{label(selectedOption?.label)}</div>
        <div className={classes.indicatorContainer}>
          <Image src={triangleDownSvg} alt="Dropdown indicator" />
        </div>
      </label>
      <select
        className={classes.select}
        aria-label={t('Language')}
        data-e2e-selector="locale-changer"
        value={selectedOption?.value}
        onChange={(event) =>
          onChange(
            options.find(
              (option) => option.value === event.target.value,
            ) as SelectOption,
          )
        }
      >
        {options.map((option) => (
          <option
            className={classes.option}
            key={option.value}
            disabled={option.disabled}
            value={option.value}
          >
            {option?.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const AMOUNT_OF_LOCALES_WHICH_DOESNT_NEED_LOCALECHANGER = 1;
const ALWAYS_ENGLISH_LOCALECHANGER_LABEL_TEXT = 'Language:';

export const LocaleChanger = () => {
  const { lang } = useTranslation('navbar');
  const { route, query, asPath } = useRouter();
  const i18nContext = useContext(I18nContext);
  const internationalizedHref = useInternationalizedHref();
  const { localeChangerConfig } = useContext(ConfigsContext);

  const betweenSquareBracketsRegex = /\[+(.*?)]+/g;
  const matches = route.match(betweenSquareBracketsRegex);
  const routeParamNamesWithMatches =
    matches &&
    matches.map((match) => ({ routeParamName: camelCase(match), match }));

  let pathname: string = route;

  if (routeParamNamesWithMatches && !isEmpty(query)) {
    routeParamNamesWithMatches.forEach(({ routeParamName, match }) => {
      pathname = pathname.replace(match, `:${routeParamName}`);
    });
  } else {
    const HASH_REGEX = /#(.*)/;
    pathname = asPath.replace(HASH_REGEX, '');
  }

  if (
    i18nContext.locales.length ===
    AMOUNT_OF_LOCALES_WHICH_DOESNT_NEED_LOCALECHANGER
  ) {
    return null;
  }

  const getRouteParamsForLocale = (locale: string) => {
    return !isEmpty(query) &&
      routeParamNamesWithMatches &&
      i18nContext?.dynamicRoutesLocaleMap?.[locale]
      ? {
          [routeParamNamesWithMatches[0].routeParamName]:
            i18nContext?.dynamicRoutesLocaleMap?.[locale],
        }
      : undefined;
  };

  return (
    <CustomLabelSelect
      options={localeChangerConfig.map((option) => {
        if (option.value === lang) {
          return {
            ...option,
            disabled: true,
          };
        }
        return option;
      })}
      selectedOption={
        localeChangerConfig.find(
          (locale) => locale.value === lang,
        ) as SelectOption
      }
      onChange={({ value: locale }) => {
        const params = getRouteParamsForLocale(locale);
        const linkHref =
          internationalizedHref(pathname, locale, params) ||
          internationalizedHref(HOME_ROUTE, locale, params);

        if (!linkHref) {
          return;
        }

        window.location.href = linkHref;
      }}
      label={(val) => (
        <span className={classes.localeChangerLabel}>
          <FaIconLight icon={faEarthAfrica} />
          <span>
            {ALWAYS_ENGLISH_LOCALECHANGER_LABEL_TEXT} <b>{val}</b>
          </span>
        </span>
      )}
    />
  );
};
