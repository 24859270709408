import React, { useMemo } from 'react';
import clsx from 'clsx';
import { ImageProps } from 'next/image';
import Image from 'next/image';

import classes from './index.module.scss';

import { HtmlRel } from '@/types/HtmlRel';

interface Props {
  description?: string;
  href?: string;
  logoSrc: ImageProps['src'];
  title?: string;
  rel?: HtmlRel;
  target?: '_blank';
  isDark?: boolean;
}

const Badge: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  description,
  href,
  logoSrc,
  title,
  rel,
  target,
  isDark = false,
}) => {
  const badgeContent = useMemo(
    () => (
      <>
        <div className={classes.logo}>
          <Image
            src={logoSrc}
            alt={`${title ? title.toLowerCase() : ''} logo`}
            sizes="69px"
            style={{
              height: 69,
              width: 69,
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        </div>
        {(!!title || !!description) && (
          <div className={classes.text}>
            <div className={classes.header}>{title}</div>
            <div className={classes.description}>{description}</div>
          </div>
        )}
      </>
    ),
    [description, logoSrc, title],
  );

  return href ? (
    <a
      className={clsx(classes.badge, isDark && classes.badge_dark)}
      href={href}
      rel={rel}
      target={target}
    >
      {badgeContent}
    </a>
  ) : (
    <div className={clsx(classes.badge, isDark && classes.badge_dark)}>
      {badgeContent}
    </div>
  );
};

export default Badge;
